<template>
  <div>
    <Footer></Footer>
      <div class="content">
        <div class="content_box">
         <span class="content_box_tilte"> 第七届“大唐杯”全国大学生新一代信息通信技术大赛 官方通知发布</span>
         <div class="content_box_BOX"> 
              <span class="content_inform">官方通知</span>
              <div class="content_text">
                <p><el-link type="info" @click="openfile1">第七届“大唐杯”全国大学生移动通信5G技术大赛-考试大纲（省赛）</el-link></p>
                <p><el-link type="info" @click="openfile2">第七届“大唐杯”全国大学生移动通信5G技术大赛-全国赛考试大纲</el-link></p>
                <p><el-link type="info" @click="openfile3">第七届“大唐杯”全国大学生移动通信5G技术大赛-章程</el-link></p>
                <p><el-link type="info" @click="openfile4">第七届“大唐杯”全国大学生移动通信技术大赛-省赛获奖通知</el-link></p>
                <p><el-link type="info" @click="openfile5">第七届“大唐杯”全国大学生移动通信技术大赛省赛理论考试启动通知</el-link></p>
                <p><el-link type="info" @click="openfile6">关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛的通知</el-link></p>
                <p><el-link type="info" @click="openfile7">关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛-通知</el-link></p>
              </div>
              <div class="pagination">
                  <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div>
         </div>
        </div>


        <div class="content_box">
         <span class="content_box_tilte"> 第七届“大唐杯”全国大学生新一代信息通信技术大赛 官方文件发布</span>
         <div class="content_box_BOXTOW"> 
              <span class="content_inform">官方文件</span>
              <div class="content_text">
                <p><el-link type="info" @click="openfile1">第七届“大唐杯”全国大学生移动通信5G技术大赛-考试大纲（省赛）</el-link></p>
                <p><el-link type="info" @click="openfile2">第七届“大唐杯”全国大学生移动通信5G技术大赛-全国赛考试大纲</el-link></p>
                <p><el-link type="info" @click="openfile3">第七届“大唐杯”全国大学生移动通信5G技术大赛-章程</el-link></p>
                <p><el-link type="info" @click="openfile4">第七届“大唐杯”全国大学生移动通信技术大赛-省赛获奖通知</el-link></p>
                <p><el-link type="info" @click="openfile5">第七届“大唐杯”全国大学生移动通信技术大赛省赛理论考试启动通知</el-link></p>
                <p><el-link type="info" @click="openfile6">关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛的通知</el-link></p>
                <p><el-link type="info" @click="openfile7">关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛-通知</el-link></p>
              </div>
              <div class="pagination">
                  <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div>
         </div>
        </div>
      </div>
  <Bottom></Bottom>
  </div>
</template>

<script>
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
export default {
  name: 'Home',
  components: {
    Footer,
    Bottom
  },
  data(){
    return {

    }
  },
  methods:{
    openfile1(){
      window.open('./static/Seventh/第七届“大唐杯”全国大学生移动通信5G技术大赛-考试大纲（省赛）.pdf')
    },
    openfile2(){
      window.open('./static/Seventh/第七届“大唐杯”全国大学生移动通信5G技术大赛-全国赛考试大纲.pdf')

    },
    openfile3(){
      window.open('./static/Seventh/第七届“大唐杯”全国大学生移动通信5G技术大赛-章程.pdf')

    },
    openfile4(){
      window.open('./static/Seventh/第七届“大唐杯”全国大学生移动通信技术大赛-省赛获奖通知.pdf')

    },
    openfile5(){
      window.open('./static/Seventh/第七届“大唐杯”全国大学生移动通信技术大赛省赛理论考试启动通知.pdf')

    },
    openfile6(){
      window.open('./static/Seventh/关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛的通知.pdf')

    },
    openfile7(){
      window.open('./static/Seventh/关于邀请参加第七届“大唐杯”全国大学生移动通信5G技术大赛-通知.pdf')

    },
  },
  mounted(){

  },
  created(){

  },
}
</script>

<style scoped lang="scss">
/deep/ .el-table .cell.el-tooltip{
  text-align: left;
}
/deep/.el-link__inner{
  color: #000;
}
/deep/.el-link__inner:hover{
  color: orangered;
}

@import './Seventh.scss';
</style>
